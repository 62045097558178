import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebaseConfig';
import { getDocs, collection, deleteDoc, doc, addDoc, serverTimestamp } from 'firebase/firestore';
import Preloader from '../Preloader/Preloader';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './AddImages.css';
import plus from "../../assets/plus.svg";
import maximize from "../../assets/maximize.svg";
import trash from "../../assets/trash.svg";
import close from "../../assets/close.svg";

function AddImages() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isImageModalOpen, setImageIsModalOpen] = useState(false);
    const imagesUrlCollectionRef = collection(db, 'imagesUrl');
    const categoriesCollectionRef = collection(db, 'categories');
    const [imagesUrlList, setImagesUrlList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);


    const [imageCategory, setImageCategory] = useState('');
    const [selectedImageForPreview, setSelectedImageForPreview] = useState('');

    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isNoDataFound, setIsNoDataFound] = useState(false);
    const [isDataSending, setIsDataSending] = useState(false);
    const [isMissingData, setIsMissingData] = useState(false);
    const [isDataSendError, setIsDataSendError] = useState(false);
    const [isDataSendSuccess, setIsDataSendSuccess] = useState(false);

    const [imageUrl, setImageUrl] = useState('');

    const getCategoryList = async () => {
        try {
            const data = await getDocs(categoriesCollectionRef);
            const filteredData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            // console.log(filteredData)
            filteredData.map((item) => {
                if (item.categoryName === "Gallery") {
                    setCategories(item.subCategories);
                }
            })
        } catch (err) {
            console.log(err);
        }
    }

    const getImagesUrlList = async () => {
        try {
            const data = await getDocs(imagesUrlCollectionRef);
            const filteredData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            setIsDataLoading(false);
            if (filteredData.length > 0) {
                setImagesUrlList(filteredData);
                // console.log(filteredData)
                setIsNoDataFound(false);
            } else {
                // console.log(data.docs);
                setIsNoDataFound(true);
            }
        } catch (err) {
            console.log(err);
        }
    }

    function handleAddImage(e) {
        e.preventDefault();
        if (
            imageUrl === '' ||
            imageCategory === ''
        ) {
            setIsMissingData(true);
            return;
        }
        setIsMissingData(false);
        setIsDataSending(true);
        const data = { imageUrl: imageUrl, category: imageCategory, date: serverTimestamp() }
        const addCategory = async () => {
            try {
                const result = await addDoc(imagesUrlCollectionRef, data);
                setIsDataSending(false);
                setIsDataSendSuccess(true);
                await getImagesUrlList();
                handleModalClose();
            } catch (err) {
                console.log(err);
                setIsDataSending(false);
                setIsDataSendError(true);
            }
        }
        addCategory();
    }


    const deleteImage = async (id) => {
        if (window.confirm("Are you sure you want to delete this Image? \nThis process can't be undone!")) {
            const pdfDoc = doc(imagesUrlCollectionRef, id);
            try {
                setIsDataLoading(true);
                const deleteIamge = await deleteDoc(pdfDoc);
                const updatedImages = imagesUrlList.filter((image) => image.id !== id);
                setImagesUrlList(updatedImages);
                setIsDataLoading(false);
                // console.log(updatedImages);
                if (updatedImages.length > 0) {
                    setIsNoDataFound(false);
                } else {
                    setIsNoDataFound(true);
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            console.log("saved");
        }
    }

    function handlePreviewImage(url) {
        setSelectedImageForPreview(url);
        setImageIsModalOpen(true);
    }

    function handleModalOpen() {
        setIsModalOpen(true);
    }
    function handleModalClose() {
        setIsModalOpen(false);
        setIsDataSendSuccess(false);
        setIsDataSendError(false);
        setIsMissingData(false);
        setImageCategory('');
    }

    useEffect(() => {
        getImagesUrlList();
        getCategoryList();
    }, []);

    return (
        <div className='content_section reports'>
            <div className="content_section_header">
                <h2 className="content_section_title">Add Gallery Images</h2>
                <div className="content_section_actions">
                    <button className='button_add' onClick={handleModalOpen}>
                        <img src={plus} alt="add" className='menu_icon' />
                        <span>Add</span>
                    </button>
                </div>
            </div>
            <div className="image_list">
                {
                    isDataLoading && <div className="no_data"><div><Preloader /></div></div>
                }
                {
                    isNoDataFound && <p className='no_data_text'>No data found</p>
                }
                {
                    (imagesUrlList.length > 0) && !isDataLoading && !isNoDataFound &&
                    imagesUrlList.map((imageUrl, index) => (
                        <div className="image_item" key={index}>
                            <div className="image_action">
                                <button onClick={() => handlePreviewImage(imageUrl.imageUrl)} className='button_primary image_action_button'><img src={maximize} alt="maximize" /></button>
                                <button onClick={() => deleteImage(imageUrl.id)} className='button_primary image_action_button'><img src={trash} alt="trash" /></button>
                            </div>
                            {/* <img src={imageUrl.imageUrl} alt="Image" /> */}
                            <img src={imageUrl.imageUrl.includes("drive.google.com") ? `https://drive.google.com/thumbnail?id=${imageUrl.imageUrl.match(/[-\w]{25,}/)[0]}` : imageUrl.imageUrl} alt="Image" />
                            {/* https://www.window-warehouse.co.uk/app/uploads/2023/02/Full-front-800x533.jpg */}
                        </div>
                    ))
                }
            </div>

            {
                isModalOpen &&
                <div className="modal">
                    <div className="modal_wrap">
                        <h2 className="modal_title">Add Image</h2>
                        <div className="modal_body">
                            <div className="modal_body_content">
                                <form onSubmit={handleAddImage}>
                                    {/* <div className="input_group">
                                        <label className='input_label' htmlFor="image_category">Image category:</label>
                                        <input onChange={e => setImageCategory(e.target.value)} id='image_category' name='image_category' type="text" className='input_box' placeholder='Image category' />
                                    </div> */}
                                    <div className="input_group">
                                        <label className='input_label' htmlFor="category">Category:</label>
                                        <select onChange={e => setImageCategory(e.target.value)} name="category" id="category" className='input_box' defaultValue={-1} required >
                                            <option value="-1" disabled={true}>Seletct category</option>
                                            {
                                                (categories.length > 0) &&
                                                categories.map((category, index) => (
                                                    <option key={index} value={category}>{category}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="input_group">
                                        <label className='input_label' htmlFor="image_link">Image link:</label>
                                        <input onChange={e => setImageUrl(e.target.value)} id='image_link' name='image_link' type="url" className='input_box' placeholder='Image link' />
                                    </div>
                                    {
                                        isDataSending &&
                                        <p className='form_status loading_message'>Sending...</p>
                                    }
                                    {
                                        isMissingData &&
                                        <p className='form_status error_message'>Reruired data missing!</p>
                                    }
                                    {
                                        isDataSendError &&
                                        <p className='form_status error_message'>Something went wrong, Try again!</p>
                                    }
                                    {
                                        isDataSendSuccess &&
                                        <p className='form_status success_message'>Successful!</p>
                                    }
                                    <div className="input_group input_group_submit">
                                        <button className='button_primary button_submit'>Add</button>
                                        <button className='button_primary button_submit' onClick={handleModalClose}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                isImageModalOpen &&
                <div className="imageModal">
                    <div className="imageModal_Wrap">
                        <div className="imageModal_imgbox">
                            <div className="closeIcon" onClick={() => setImageIsModalOpen(false)}>
                                <img src={close} alt="Close" />
                            </div>
                            <img src={selectedImageForPreview.includes("drive.google.com") ? `https://drive.google.com/thumbnail?id=${selectedImageForPreview.match(/[-\w]{25,}/)[0]}` : selectedImageForPreview} alt="Image" className="imageModal_image" />
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default AddImages;