import React, { useEffect, useState } from 'react';
import { db, storage } from '../../config/firebaseConfig';
import { getDocs, collection, deleteDoc, doc } from 'firebase/firestore';
import { ref, deleteObject } from "firebase/storage";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Preloader from '../Preloader/Preloader';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import "./Reports.css";
import maximize from "../../assets/maximize.svg";
import close from "../../assets/close.svg";

function Reports() {
    const [isImageModalOpen, setImageIsModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isNoDataFound, setIsNoDataFound] = useState(false);
    const [reports, setReports] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);
    const reportsCollectionRef = collection(db, 'reports');
    const [selectedImageForPreview, setSelectedImageForPreview] = useState('');

    useEffect(() => {
        // console.log(reports.length);
        const getReportsList = async () => {
            try {
                const data = await getDocs(reportsCollectionRef);
                const filteredData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                filteredData.sort((a, b) => a.refId - b.refId)
                setIsDataLoading(false);
                if (filteredData.length > 0) {
                    setReports(filteredData);
                    console.log(filteredData);
                    setIsNoDataFound(false);
                } else {
                    setIsNoDataFound(true);
                }

            } catch (err) {
                console.log(err);
            }
        }
        getReportsList();
    }, []);

    function dateDisplay(reportDate) {
        const date = reportDate.toDate().toLocaleString();
        return date;
    }

    const deleteReport = async (id) => {
        if (window.confirm("Are you sure you want to delete this report? \nThis process can't be undone!")) {
            const reportDoc = doc(reportsCollectionRef, id);
            const selectedReportToDelete = reports.filter((report) => report.id === id);
            const imagesDelete = selectedReportToDelete[0].data.images;
            console.log("delete item", imagesDelete);
            if (imagesDelete !== undefined && imagesDelete.length > 0) {
                imagesDelete.map(async (image) => {
                    const desertRef = ref(storage, image);
                    await deleteObject(desertRef).then(() => {
                        console.log("deleted img: ", image);
                    }).catch((error) => {
                        console.log("Error deleting img: ", image);
                    });
                })
            }

            try {
                setIsDataLoading(true);
                const deleteReport = await deleteDoc(reportDoc);
                const updatedReports = reports.filter((report) => report.id !== id);
                if (updatedReports.length > 0) {
                    setReports(updatedReports);
                    // console.log(updatedReports);
                    setIsDataLoading(false);
                    setIsNoDataFound(false);
                } else {
                    setIsNoDataFound(true);
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            console.log("saved");
        }
    }

    function viewReport(id) {
        setSelectedReport(reports.filter((report) => report.id == id));
        setIsModalOpen(true);
    }

    function handleModalClose() {
        setIsModalOpen(false);
    }

    return (
        <div className='content_section reports'>
            <div className="content_section_header">
                <h2 className="content_section_title">All Reports</h2>
            </div>
            <Table className="table">
                <Thead className="table_head">
                    <Tr>
                        <Th>Reference id</Th>
                        <Th>Job Number</Th>
                        <Th>Window Number</Th>
                        <Th>Issue Type</Th>
                        <Th>Report Status</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody className="table_body">
                    {
                        isDataLoading && <Tr className="no_data"><Td><Preloader /></Td></Tr>
                    }
                    {
                        isNoDataFound && <Tr className="no_data"><Td><p className='no_data_text'>No data found</p></Td></Tr>
                    }
                    {
                        (reports.length > 0) && !isDataLoading && !isNoDataFound &&
                        reports.map((report, index) => (
                            <Tr key={index}>
                                <Td>{report.refId}</Td>
                                <Td>{report.data.jobNo}</Td>
                                <Td>{report.data.winNo}</Td>
                                <Td>
                                    {
                                        (report.data.issueType.length > 0) ?
                                            (report.data.issueType.length > 1) ?
                                                <>
                                                    <span className='issueType'>{report.data.issueType[0]}</span>
                                                    <span className='issueType'>{`${report.data.issueType.length - 1} more..`}</span>
                                                </>
                                                :
                                                <span className='issueType'>{report.data.issueType[0]}</span>
                                            :
                                            "N/A"
                                    }
                                </Td>
                                <Td>{report?.status}</Td>
                                <Td>
                                    <div className="table_action">
                                        <button className='button_primary' onClick={() => viewReport(report.id)}>View</button>
                                        <button className='button_primary' onClick={() => deleteReport(report.id)}>Delete</button>
                                    </div>
                                </Td>
                            </Tr>
                        ))
                    }
                </Tbody>
            </Table>

            {
                isModalOpen &&
                <div className="modal">
                    <div className="modal_wrap">
                        <h2 className="modal_title">Report</h2>
                        <div className="modal_body">
                            <div className="modal_body_content">
                                <div className="content_row">
                                    <div className="content_cell">
                                        <p className="content_title">Reference id</p>
                                    </div>
                                    <div className="content_cell">
                                        <p className="content_text">{selectedReport[0]?.refId}</p>
                                    </div>
                                </div>
                                <div className="content_row">
                                    <div className="content_cell">
                                        <p className="content_title">Report status</p>
                                    </div>
                                    <div className="content_cell">
                                        <p className="content_text">{selectedReport[0]?.status}</p>
                                    </div>
                                </div>
                                <div className="content_row">
                                    <div className="content_cell">
                                        <p className="content_title">Reported Date</p>
                                    </div>
                                    <div className="content_cell">
                                        <p className="content_text">{dateDisplay(selectedReport[0]?.data.reportedDate)}</p>
                                    </div>
                                </div>
                                <div className="content_row">
                                    <div className="content_cell">
                                        <p className="content_title">Job Number</p>
                                    </div>
                                    <div className="content_cell">
                                        <p className="content_text">{selectedReport[0]?.data.jobNo}</p>
                                    </div>
                                </div>
                                <div className="content_row">
                                    <div className="content_cell">
                                        <p className="content_title">Window Number</p>
                                    </div>
                                    <div className="content_cell">
                                        <p className="content_text">{selectedReport[0]?.data.winNo}</p>
                                    </div>
                                </div>
                                <div className="content_row">
                                    <div className="content_cell">
                                        <p className="content_title">Issue Type</p>
                                    </div>
                                    <div className="content_cell">
                                        {/* <p className="content_text">{selectedReport[0]?.data.issueType.map}</p> */}
                                        <p className="content_text">
                                            {
                                                (selectedReport[0]?.data.issueType.length > 0) ?
                                                    selectedReport[0]?.data.issueType.map((type, index) => (<span className='issueType' key={index}>{type}</span>))
                                                    :
                                                    "N/A"
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="content_row">
                                    <div className="content_cell">
                                        <p className="content_title">Issue Details</p>
                                    </div>
                                    <div className="content_cell">
                                        <p className="content_text">{
                                            (selectedReport[0].data.issueDescription !== null && selectedReport[0].data.issueDescription !== '') ? selectedReport[0].data.issueDescription : "N/A"
                                        }</p>
                                    </div>
                                </div>
                                <div className="content_row">
                                    <div className="content_cell">
                                        <p className="content_title">customerDetails</p>
                                    </div>
                                    <div className="content_cell">
                                        <p className="content_text">{selectedReport[0]?.data.customerEmail}</p>
                                        <p className="content_text">{selectedReport[0]?.data.customerPhone}</p>
                                    </div>
                                </div>
                                <div className="content_row">
                                    <div className="content_cell">
                                        <p className="content_title">Images</p>
                                    </div>
                                    <div className="content_cell">
                                        <div className="cell_image_list">
                                            {
                                                (selectedReport[0]?.data?.images !== undefined && selectedReport[0]?.data?.images.length > 0) ?
                                                    selectedReport[0]?.data.images.map((imageLink, index) => (
                                                        <div key={index} className="cell_image_box">
                                                            <span className="cell_image_link" onClick={() => { setSelectedImageForPreview(imageLink); setImageIsModalOpen(true) }}>
                                                                <img src={maximize} alt="maximize" />
                                                            </span>
                                                            <img src={imageLink} alt="Image" />
                                                        </div>
                                                    ))
                                                    :
                                                    "N/A"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal_action">
                            <button className='button_primary button_submit' onClick={handleModalClose}>Done</button>
                        </div>
                    </div>
                </div>
            }

            {
                isImageModalOpen &&
                <div className="imageModal">
                    <div className="imageModal_Wrap">
                        <div className="imageModal_imgbox">
                            <div className="closeIcon" onClick={() => setImageIsModalOpen(false)}>
                                <img src={close} alt="Close" />
                            </div>
                            <img src={selectedImageForPreview} alt="Image" className="imageModal_image" />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Reports;