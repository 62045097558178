import React from 'react';
import './Navigation.css';
import plus from "../../assets/plus.svg";
import logoutIcon from "../../assets/logout.svg";
import { NavLink } from "react-router-dom";

function Navigation({ logout }) {

    return (
        <div className='navigation'>
            <h3 className='logo'>WW Admin</h3>
            <ul className="menu">
                <li>
                    <NavLink to="/"
                        className={({ isActive }) =>
                            isActive ? "menu_item active" : "menu_item"
                        }
                    >
                        <img src={plus} alt="add" className='menu_icon' />
                        <p className="menu_label">
                            Manage Reports
                        </p>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/manage_pdf" className={({ isActive }) =>
                        isActive ? "menu_item active" : "menu_item"
                    }>
                        <img src={plus} alt="add" className='menu_icon' />
                        <p className="menu_label">
                            Manage PDF
                        </p>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/pdf_category" className={({ isActive }) =>
                        isActive ? "menu_item active" : "menu_item"
                    }>
                        <img src={plus} alt="add" className='menu_icon' />
                        <p className="menu_label">
                            PDF Category
                        </p>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/add_images" className={({ isActive }) =>
                        isActive ? "menu_item active" : "menu_item"
                    }>
                        <img src={plus} alt="add" className='menu_icon' />
                        <p className="menu_label">
                            Add Images
                        </p>
                    </NavLink>
                </li>
            </ul>
            <div className="loguot">
                <div className="menu_item" onClick={logout}>
                    <img src={logoutIcon} alt="add" className='menu_icon' />
                    <p className="menu_label">
                        Log Out
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Navigation;