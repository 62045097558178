import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './Login.css';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../config/firebaseConfig';


function Login() {
    const [token, setToken] = useState(localStorage.getItem('jwt'));
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState('');

    const navigate = useNavigate();


    useEffect(() => {
        auth.onAuthStateChanged(() => {
            if (auth?.currentUser !== null) {
                if (token === auth.currentUser.accessToken) {
                    localStorage.setItem("jwt", auth.currentUser.accessToken);
                    // console.log("Go to main 1");
                    navigate("/");
                }
            } else {
                // console.log("Go to Login");
            }
        });
    }, [token]);


    const login = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    localStorage.setItem("jwt", auth.currentUser.accessToken);
                    // console.log("Go to main 2");
                    navigate("/");
                })
                .catch((error) => {
                    // console.log(error)
                    setLoginError(true);
                    const errorCode = error.code;
                    const errorMessagee = error.message;
                    // console.log(errorCode);
                    setLoginErrorMessage(errorCode);
                });
        }
        catch (err) {
            console.log(err);
        }

    }


    // function signup(e) {
    //     e.preventDefault();

    //     createUserWithEmailAndPassword(auth, email, password)
    //         .then((userCredential) => {
    //             // Signed in 
    //             const user = userCredential.user;
    //             // ...
    //         })
    //         .catch((error) => {
    //             const errorCode = error.code;
    //             const errorMessage = error.message;
    //             // ..
    //         });
    // }

    return (
        <div className='login_page'>
            <div className="login_container">
                <h1 className="login_title_text">Welcome!</h1>
                <form className='input_form' onSubmit={(e) => login(e)}>
                    <div className="input_group">
                        <label className='input_label' htmlFor="email">Email:</label>
                        <input value={email} id='email' type="email" className='input_box' placeholder='Email' onChange={e => setEmail(e.target.value)} required />
                    </div>
                    <div className="input_group">
                        <label className='input_label' htmlFor="password">Password:</label>
                        <input value={password} id='password' type="password" className='input_box' placeholder='Password' onChange={e => setPassword(e.target.value)} required />
                    </div>
                    {
                        loginError &&
                        <p className='error_message form_status'>{loginErrorMessage}</p>
                    }
                    <div className="input_group">
                        <button className='button_primary button_submit' type='submit'>Login</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login;
