
import { useEffect, useState } from 'react';
import {
  Routes,
  Route,
  Outlet,
  useNavigate
} from "react-router-dom";
import { auth } from '../../config/firebaseConfig';
import { signOut } from "firebase/auth";
import Navigation from '../Navigation/Navigation';
import Reports from '../Reports/Reports';
import ManagePDF from '../ManagePDF/ManagePDF';
import PDFCategory from '../PDFCategory/PDFCategory';
import AddImages from '../AddImages/AddImages';
import Preloader from '../Preloader/Preloader';
import './App.css';
import Login from '../Login/Login';

function App() {
  const [token, setToken] = useState(localStorage.getItem('jwt'));
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [reports, setReports] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged(() => {
      if (auth?.currentUser !== null) {
        setIsLoggedIn(true);
        // console.log("report page")
      } else {
        // console.log("Go to Login")
        navigate("/login");
      }
    });
    // console.log(auth?.currentUser)
  }, [token]);

  const handleLogOut = async () => {
    signOut(auth).then(() => {
      // console.log("sign out complete");
      localStorage.removeItem('jwt');
      setIsLoggedIn(false);
    }).catch((err) => {
      console.log("Signout fail", err)
    })
  }

  return (
    <Routes >
      {(token !== null) && isLoggedIn &&
        <Route path='/' element={<Layout logout={handleLogOut} />} >
          <Route index element={<Reports />} exact />
          <Route path="manage_pdf" element={<ManagePDF />} exact />
          <Route path="pdf_category" element={<PDFCategory />} exact />
          <Route path="add_images" element={<AddImages />} exact />
        </Route>
      }
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Preloader />} />
    </Routes>
  )
}

function Layout({ logout }) {
  return (
    <div className="app">
      <Navigation logout={logout}></Navigation>
      <div className="content_wrapper">
        <Outlet />
      </div>
    </div>
  );
}

export default App;