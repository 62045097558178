import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebaseConfig';
import { getDocs, collection, deleteDoc, doc, addDoc, updateDoc, arrayRemove, arrayUnion, serverTimestamp } from 'firebase/firestore';
import Preloader from '../Preloader/Preloader';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './PDFCategory.css';
import plus from "../../assets/plus.svg";
import trash from "../../assets/trash.svg";

function PDFCategory() {
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isNoDataFound, setIsNoDataFound] = useState(false);
    const [isDataSending, setIsDataSending] = useState(false);
    const [isMissingData, setIsMissingData] = useState(false);
    const [isDataSendError, setIsDataSendError] = useState(false);
    const [isDataSendSuccess, setIsDataSendSuccess] = useState(false);

    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const [isSubCategoryModalOpen, setIsSubCategoryModalOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newSubCategoryName, setSubNewCategoryName] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCategoryForNewSub, setSelectedCategoryForNewSub] = useState('');
    const categoriesCollectionRef = collection(db, 'categories');

    const getCategoryList = async () => {
        try {
            const data = await getDocs(categoriesCollectionRef);
            const filteredData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            setIsDataLoading(false);
            if (filteredData.length > 0) {
                setCategories(filteredData);
                // console.log(filteredData);
                setIsNoDataFound(false);
                if (selectedCategory !== '') {
                    populateSubCategories();
                }
            } else {
                console.log(data.docs);
                setIsNoDataFound(true);
            }
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        getCategoryList();
    }, []);

    function handleAddCategory(e) {
        e.preventDefault();
        setIsDataSending(true);
        const data = { categoryName: newCategoryName, subCategories: [], date: serverTimestamp() }
        const addCategory = async () => {
            try {
                const result = await addDoc(categoriesCollectionRef, data);
                setIsDataSending(false);
                setIsDataSendSuccess(true);
                await getCategoryList();
                handleCategoryModalClose();
            } catch (err) {
                console.log(err);
                setIsDataSending(false);
                setIsDataSendError(true);
            }
        }
        addCategory();
    }

    function handleAddSubCategory(e) {
        e.preventDefault();
        if (
            selectedCategoryForNewSub === '' ||
            newSubCategoryName === ''
        ) {
            setIsMissingData(true);
            return;
        }
        const selectedCategoryRef = doc(db, 'categories', `${selectedCategoryForNewSub}`);
        const addSubCategory = async () => {
            try {
                const result = await updateDoc(selectedCategoryRef, {
                    subCategories: arrayUnion(newSubCategoryName)
                });
                setIsDataSending(false);
                setIsDataSendSuccess(true);

                await getCategoryList();
                populateSubCategories();
            } catch (err) {
                console.log(err);
                setIsDataSending(false);
                setIsDataSendError(true);
            }
            handleSubCategoryModalClose();
            handleSelectedCategoryId(selectedCategory);
        }
        addSubCategory();
    }

    const deleteCategory = async (id) => {
        if (window.confirm("Are you sure you want to delete this Caregory? \nThis process can't be undone!")) {
            const categoryDoc = doc(categoriesCollectionRef, id);
            try {
                const deleteCategory = await deleteDoc(categoryDoc);
                // console.log("delete");
                await getCategoryList();
            } catch (err) {
                console.log(err);
            }
        } else {
            console.log("saved");
        }
    }

    const deleteSubCategory = async (subCategoryName) => {
        if (window.confirm("Are you sure you want to delete this Sub Caregory? \nThis process can't be undone!")) {
            const selectedCategoryRef = doc(db, 'categories', `${selectedCategory}`);
            try {
                const deleteSubCategory = await updateDoc(selectedCategoryRef, {
                    subCategories: arrayRemove(subCategoryName)
                });
                // console.log("delete");
                await getCategoryList();
            } catch (err) {
                console.log(err);
            }
        } else {
            console.log("saved");
        }
    }

    function populateSubCategories() {
        categories.map((category) => {
            if (category.id === selectedCategory) {
                setSubCategories([...category.subCategories]);
            }
        });
    }

    useEffect(() => {
        populateSubCategories();
    }, [selectedCategory, categories]);

    // useEffect(() => {
    //     console.log(subCategories)
    // }, [categories, subCategories]);

    function handleSelectedCategoryId(id) {
        setSelectedCategory(id);
        populateSubCategories();
    }

    function handleCategoryModalOpen() {
        setIsCategoryModalOpen(true);
    }
    function handleCategoryModalClose() {
        setIsCategoryModalOpen(false);
        setIsDataSendSuccess(false);
        setIsDataSendError(false);
        setIsMissingData(false);
        setNewCategoryName('');
    }
    function handleSubCategoryModalOpen() {
        setIsSubCategoryModalOpen(true);
    }
    function handleSubCategoryModalClose() {
        setIsSubCategoryModalOpen(false);
        setIsDataSendSuccess(false);
        setIsDataSendError(false);
        setIsMissingData(false);
        setSubNewCategoryName('');
        setSelectedCategoryForNewSub('');
    }

    return (
        <div className='content_section '>
            <div className="content_section_header">
                <h2 className="content_section_title">PDF Category Manager</h2>
                <div className="content_section_actions">
                    <button className='button_add' onClick={handleCategoryModalOpen}>
                        <img src={plus} alt="add" className='menu_icon' />
                        <span>Add Category</span>
                    </button>
                    <button className='button_add' onClick={handleSubCategoryModalOpen}>
                        <img src={plus} alt="add" className='menu_icon' />
                        <span>Add Sub Category</span>
                    </button>
                </div>
            </div>
            <div className="category_wrap">
                <div className="single_list">
                    <h2 className="category_list_title">Category</h2>
                    {
                        isDataLoading && <div className="no_data"><div><Preloader /></div></div>
                    }
                    {
                        isNoDataFound && <p className='no_data_text'>No data found</p>
                    }
                    {
                        (categories.length > 0) &&
                        categories.map((category, index) => (
                            <div className={(selectedCategory === category.id) ? "category_item active" : 'category_item'} key={index} onClick={() => handleSelectedCategoryId(category.id)}>
                                <p>{category.categoryName}</p>
                                <button onClick={() => deleteCategory(category.id)} className='button_primary image_action_button'><img src={trash} alt="trash" /></button>
                            </div>
                        ))
                    }
                </div>
                {
                    (selectedCategory !== '') &&
                    <div className="single_list">
                        <h2 className="category_list_title">Sub Category</h2>
                        {
                            (subCategories.length === 0) ? <p className='no_data_text'>No data found</p> : ''
                        }
                        {
                            (subCategories.length > 0) &&
                            subCategories.map((subCategory, index) => (
                                <div className="category_item" key={index} onClick={() => deleteSubCategory(subCategory)}>
                                    <p>{subCategory}</p>
                                    <button className='button_primary image_action_button'><img src={trash} alt="trash" /></button>
                                </div>
                            ))
                        }
                    </div>
                }

            </div>

            {
                isCategoryModalOpen &&
                <div className="modal">
                    <div className="modal_wrap">
                        <h2 className="modal_title">Add Category</h2>
                        <div className="modal_body">
                            <div className="modal_body_content">
                                <form onSubmit={(e) => handleAddCategory(e)}>
                                    <div className="input_group">
                                        <label className='input_label' htmlFor="category_name">Category Name:</label>
                                        <input value={newCategoryName} onChange={e => setNewCategoryName(e.target.value)} id='category_name' name='category_name' type="text" className='input_box' placeholder='Category Name' required />
                                    </div>
                                    {
                                        isDataSending &&
                                        <p className='form_status loading_message'>Sending...</p>
                                    }
                                    {
                                        isMissingData &&
                                        <p className='form_status error_message'>Reruired data missing!</p>
                                    }
                                    {
                                        isDataSendError &&
                                        <p className='form_status error_message'>Something went wrong, Try again!</p>
                                    }
                                    {
                                        isDataSendSuccess &&
                                        <p className='form_status success_message'>Successful!</p>
                                    }
                                    <div className="input_group input_group_submit">
                                        <button className='button_primary button_submit'>Add</button>
                                        <button className='button_primary button_submit' onClick={handleCategoryModalClose}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                isSubCategoryModalOpen &&
                <div className="modal">
                    <div className="modal_wrap">
                        <h2 className="modal_title">Add Category</h2>
                        <div className="modal_body">
                            <div className="modal_body_content">
                                <form onSubmit={handleAddSubCategory}>
                                    <div className="input_group">
                                        <label className='input_label' htmlFor="category">Select Category:</label>
                                        <select onChange={e => setSelectedCategoryForNewSub(e.target.value)} name="category" id="category" className='input_box' defaultValue="-1">
                                            <option value="-1" disabled={true}>Seletct category</option>
                                            {
                                                (categories.length > 0) &&
                                                categories.map((category, index) => (
                                                    <option key={category.id} value={category.id}>{category.categoryName}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="input_group">
                                        <label className='input_label' htmlFor="sub_category_name">Sub Category Name:</label>
                                        <input value={newSubCategoryName} onChange={e => setSubNewCategoryName(e.target.value)} id='sub_category_name' name='sub_category_name' type="text" className='input_box' placeholder='Sub Category Name' required />
                                    </div>
                                    {
                                        isDataSending &&
                                        <p className='form_status loading_message'>Sending...</p>
                                    }
                                    {
                                        isMissingData &&
                                        <p className='form_status error_message'>Reruired data missing!</p>
                                    }
                                    {
                                        isDataSendError &&
                                        <p className='form_status error_message'>Something went wrong, Try again!</p>
                                    }
                                    {
                                        isDataSendSuccess &&
                                        <p className='form_status success_message'>Successful!</p>
                                    }
                                    <div className="input_group input_group_submit">
                                        <button className='button_primary button_submit'>Add</button>
                                        <button className='button_primary button_submit' onClick={handleSubCategoryModalClose}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default PDFCategory;