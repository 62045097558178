import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCZXjj5FWReZ2VrpbDq0JG0CMNHrmg7_-E",
    authDomain: "windowwarehouse-9661f.firebaseapp.com",
    databaseURL: "https://windowwarehouse-9661f-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "windowwarehouse-9661f",
    storageBucket: "windowwarehouse-9661f.appspot.com",
    messagingSenderId: "374665772146",
    appId: "1:374665772146:web:cc957c57bb0f74feef56eb"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage();