import "./Preloader.css"
function Preloader() {
    return (
        <div className='preloader'>
            <div className="loader loader1">
                <span></span>
            </div>
        </div>
    )
}

export default Preloader;