import { useRouteError } from "react-router-dom";
import "./ErrorPage.css";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div className="error_page">
            <h1 className="error_page_title">Oops!</h1>
            <p className="error_page_text">Sorry, an unexpected error has occurred.</p>
            <p className="error_page_message">
                {/* <i>{error.statusText || error.message}</i> */}
            </p>
        </div>
    );
}