import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebaseConfig';
import { getDocs, collection, deleteDoc, updateDoc, doc, addDoc, serverTimestamp } from 'firebase/firestore';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Preloader from '../Preloader/Preloader';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './ManagePDF.css';
import plus from "../../assets/plus.svg";

function ManagePDF() {
    const pdf_resourcesRef = collection(db, 'pdf_resources');
    const categoriesCollectionRef = collection(db, 'categories');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [pdfList, setPdfList] = useState([]);

    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isNoDataFound, setIsNoDataFound] = useState(false);
    const [isDataSending, setIsDataSending] = useState(false);
    const [isMissingData, setIsMissingData] = useState(false);
    const [isDataSendError, setIsDataSendError] = useState(false);
    const [isDataSendSuccess, setIsDataSendSuccess] = useState(false);

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [PdfName, setPdfName] = useState('');
    const [pdf_link, setPdf_link] = useState('');
    const [pdf_thumbnail_link, setPdf_thumbnail_link] = useState('');

    const [editItemId, setEditItemId] = useState('');
    const [editItemName, setEditItemName] = useState('');
    const [editItemThumbnail_url, setEditItemThumbnail_url] = useState('');
    const [editItemUrl, setEditItemUrl] = useState('');
    const [editItemSubCategory, setEditItemSubCategory] = useState('');
    const [editItemCategory, setEditItemCategory] = useState('');

    const getCategoryList = async () => {
        try {
            const data = await getDocs(categoriesCollectionRef);
            const filteredData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            setCategories(filteredData);
        } catch (err) {
            console.log(err);
        }
    }

    const getPDF = async () => {
        try {
            const data = await getDocs(pdf_resourcesRef);
            const filteredData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id, date: doc.data().date.toDate().toDateString() }));
            // toLocaleString
            setIsDataLoading(false);
            if (filteredData.length > 0) {
                setPdfList(filteredData);
                // console.log(filteredData);
                setIsNoDataFound(false);
            } else {
                setIsNoDataFound(true);
            }

        } catch (err) {
            console.log(err);
        }
    }

    const deletePDF = async (id) => {
        if (window.confirm("Are you sure you want to delete this PDF? \nThis process can't be undone!")) {
            const pdfDoc = doc(pdf_resourcesRef, id);
            try {
                setIsDataLoading(true);
                const deletepdf = await deleteDoc(pdfDoc);
                const updatedPDFs = pdfList.filter((pdf) => pdf.id !== id);
                setIsDataLoading(false);
                if (updatedPDFs.length > 0) {
                    setPdfList(updatedPDFs);
                    // console.log(updatedPDFs);
                    setIsNoDataFound(false);
                } else {
                    setIsNoDataFound(true);
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            console.log("saved");
        }
    }

    function handleSelectCategory(id) {
        categories.map((category) => {
            if (category.id === id) {
                setSelectedCategory(category);
                setSubCategories(category.subCategories);
            }
        });
    }

    function handleAddpdf(e) {
        e.preventDefault();
        if (
            PdfName === '' ||
            selectedCategory === '' ||
            selectedSubCategory === '' ||
            pdf_link === '' ||
            pdf_thumbnail_link === ''
        ) {
            setIsMissingData(true);
            return;
        }
        setIsMissingData(false);
        setIsDataSending(true);
        const data = {
            name: PdfName,
            category: selectedCategory,
            subCategory: selectedSubCategory,
            url: pdf_link,
            thumbnail_url: pdf_thumbnail_link,
            date: serverTimestamp()
        }
        console.log(data)
        const addPdf = async () => {
            try {
                const result = await addDoc(pdf_resourcesRef, data);
                setIsDataSending(false);
                setIsDataSendSuccess(true);
                await getPDF();
                handleModalClose();
                console.log(result);
            } catch (err) {
                console.log(err);
                setIsDataSending(false);
                setIsDataSendError(true);
            }
        }
        addPdf();
    }

    function handleModalOpen() {
        setIsModalOpen(true);
    }
    function handleModalClose() {
        setIsModalOpen(false);
        setIsDataSendSuccess(false);
        setIsDataSendError(false);
        setIsMissingData(false);
        setSelectedCategory('');
        setSelectedSubCategory('');
        setPdfName('');
        setPdf_link('');
        setPdf_thumbnail_link('');


        setEditItemId('');
        setEditItemName('');
        setEditItemThumbnail_url('');
        setEditItemUrl('');
        setEditItemSubCategory('');
        setEditItemCategory('');

        setIsEditModalOpen(false);
    }

    useEffect(() => {
        getCategoryList();
        getPDF();
    }, []);


    function editPDF(item) {
        setEditItemId(item.id);
        setEditItemName(item.name);
        setEditItemThumbnail_url(item.thumbnail_url);
        setEditItemUrl(item.url);
        setEditItemSubCategory(item.subCategory);
        setEditItemCategory(item.category.categoryName);

        setIsEditModalOpen(true);
        // console.log(
        //     item.id,
        //     item.name,
        //     item.category.id,
        //     item.subCategory,
        //     item.thumbnail_url,
        //     item.url,
        // )
    }

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        // editItemId editItemName editItemThumbnail_url editItemUrl editItemSubCategory editItemCategory
        if (editItemName !== '' && editItemThumbnail_url !== '' && editItemUrl !== '') {
            const pdfDoc = doc(pdf_resourcesRef, editItemId);
            const update = await updateDoc(pdfDoc, {
                "name": editItemName,
                "url": editItemUrl,
                "thumbnail_url": editItemThumbnail_url,
            });

            // console.log(update);
            getPDF();
            handleModalClose();
        } else {
            setIsMissingData(false)
        }
    }


    // useEffect(() => {
    //     console.log(editItem);
    // }, [editItem]);

    return (
        <div className='content_section manage_pdf'>
            <div className="content_section_header">
                <h2 className="content_section_title">PDF Manager</h2>
                <div className="content_section_actions">
                    <button className='button_add' onClick={handleModalOpen}>
                        <img src={plus} alt="add" className='menu_icon' />
                        <span>Add PDF</span>
                    </button>
                </div>
            </div>
            <Table className="table">
                <Thead className="table_head">
                    <Tr>
                        <Th>Thumbnail</Th>
                        <Th>Name</Th>
                        <Th>Category</Th>
                        <Th>Sub Category</Th>
                        <Th>Created At</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody className="table_body">
                    {
                        isDataLoading && <Tr className="no_data"><Td><Preloader /></Td></Tr>
                    }
                    {
                        isNoDataFound && <Tr className="no_data"><Td><p className='no_data_text'>No data found</p></Td></Tr>
                    }
                    {
                        (pdfList.length > 0) && !isDataLoading && !isNoDataFound &&
                        pdfList.map((item, index) => (
                            <Tr key={index}>
                                <Td>
                                    <div className="cell_image_box">
                                        <img src={`https://drive.google.com/uc?export=view&id=${item.thumbnail_url.match(/[-\w]{25,}/)[0]}`} alt="Image" />
                                    </div>
                                </Td>
                                <Td>{item.name}</Td>
                                <Td>{item.category.categoryName}</Td>
                                <Td>{item.subCategory}</Td>
                                <Td>{item.date}</Td>
                                <Td>
                                    <div className="table_action">
                                        <button onClick={() => editPDF(item)} className='button_primary'>Edit</button>
                                        <button onClick={() => deletePDF(item.id)} className='button_primary'>Delete</button>
                                        {/* <button className='button_primary' onClick={() => deleteReport(item.id)}>Delete</button> */}
                                    </div>
                                </Td>
                            </Tr>
                        ))
                    }
                </Tbody>
            </Table>
            {
                isEditModalOpen &&
                <div className="modal">
                    <div className="modal_wrap">
                        <h2 className="modal_title">Add PDF</h2>
                        <div className="modal_body">
                            <div className="modal_body_content">
                                <form onSubmit={handleEditSubmit}>
                                    <div className="input_group">
                                        <label className='input_label' htmlFor="name">Name:</label>
                                        <input onChange={(e) => { setEditItemName(e.target.value); }} value={editItemName} id='name' name='name' type="text" className='input_box' placeholder='Name' required />
                                    </div>
                                    <div className="input_group">
                                        <p className="input_label">Category</p>
                                        <div className="input_box inactive">
                                            <p>{editItemCategory}</p>
                                        </div>
                                    </div>
                                    <div className="input_group">
                                        <p className="input_label">Sub Category</p>
                                        <div className="input_box inactive">
                                            <p>{editItemSubCategory}</p>
                                        </div>
                                    </div>
                                    <div className="input_group">
                                        <label className='input_label' htmlFor="pdf_file">PDF link:</label>
                                        <input onChange={e => setEditItemUrl(e.target.value)} value={editItemUrl} id='pdf_file_link' name='pdf_file_link' type="url" className='input_box' placeholder='PDF link' required />
                                    </div>
                                    <div className="input_group">
                                        <label className='input_label' htmlFor="pdf_thumbnail_link">PDF Thumbnail link:</label>
                                        <input onChange={e => setEditItemThumbnail_url(e.target.value)} value={editItemThumbnail_url} id='pdf_thumbnail_link' name='pdf_thumbnail_link' type="url" className='input_box' placeholder='PDF Thumbnail link' required />
                                    </div>

                                    {
                                        isDataSending &&
                                        <p className='form_status loading_message'>Sending...</p>
                                    }
                                    {
                                        isMissingData &&
                                        <p className='form_status error_message'>Reruired data missing!</p>
                                    }
                                    {
                                        isDataSendError &&
                                        <p className='form_status error_message'>Something went wrong, Try again!</p>
                                    }
                                    {
                                        isDataSendSuccess &&
                                        <p className='form_status success_message'>Successful!</p>
                                    }

                                    <div className="input_group input_group_submit">
                                        <button className='button_primary button_submit'>Submit</button>
                                        <button className='button_primary button_submit' onClick={handleModalClose}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                isModalOpen &&
                <div className="modal">
                    <div className="modal_wrap">
                        <h2 className="modal_title">Add PDF</h2>
                        <div className="modal_body">
                            <div className="modal_body_content">
                                <form onSubmit={handleAddpdf}>
                                    <div className="input_group">
                                        <label className='input_label' htmlFor="name">Name:</label>
                                        <input onChange={(e) => { setPdfName(e.target.value); setIsMissingData(false) }} id='name' name='name' type="text" className='input_box' placeholder='Name' required />
                                    </div>
                                    <div className="input_group">
                                        <label className='input_label' htmlFor="category">Category:</label>
                                        <select onChange={e => handleSelectCategory(e.target.value)} name="category" id="category" className='input_box' defaultValue={-1} required >
                                            <option value="-1" disabled={true}>Seletct category</option>
                                            {
                                                (categories.length > 0) &&
                                                categories.map((category, index) => (
                                                    <option key={category.id} value={category.id}>{category.categoryName}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    {
                                        selectedCategory !== '' &&
                                        <div className="input_group">
                                            <label className='input_label' htmlFor="sub_category">Sub Category:</label>
                                            <select onChange={e => { setSelectedSubCategory(e.target.value); setIsMissingData(false) }} name="sub_category" id="sub_category" className='input_box' defaultValue={-1} required>
                                                <option value="-1" disabled={true}>Seletct sub category</option>
                                                {
                                                    (subCategories.length > 0) &&
                                                    subCategories.map((subCategory, index) => (
                                                        <option key={index} value={subCategory}>{subCategory}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    }

                                    <div className="input_group">
                                        <label className='input_label' htmlFor="pdf_file">PDF link:</label>
                                        <input onChange={e => setPdf_link(e.target.value)} id='pdf_file_link' name='pdf_file_link' type="url" className='input_box' placeholder='PDF link' required />
                                    </div>
                                    <div className="input_group">
                                        <label className='input_label' htmlFor="pdf_thumbnail_link">PDF Thumbnail link:</label>
                                        <input onChange={e => setPdf_thumbnail_link(e.target.value)} id='pdf_thumbnail_link' name='pdf_thumbnail_link' type="url" className='input_box' placeholder='PDF Thumbnail link' required />
                                    </div>
                                    {/* <div className="input_group">
                                        <label className='input_label' htmlFor="pdf_file">Upload PDF:</label>
                                        <input type="file" name="pdf_file" id="pdf_file" className='input_box' />
                                    </div> */}
                                    {
                                        isDataSending &&
                                        <p className='form_status loading_message'>Sending...</p>
                                    }
                                    {
                                        isMissingData &&
                                        <p className='form_status error_message'>Reruired data missing!</p>
                                    }
                                    {
                                        isDataSendError &&
                                        <p className='form_status error_message'>Something went wrong, Try again!</p>
                                    }
                                    {
                                        isDataSendSuccess &&
                                        <p className='form_status success_message'>Successful!</p>
                                    }

                                    <div className="input_group input_group_submit">
                                        <button className='button_primary button_submit'>Submit</button>
                                        <button className='button_primary button_submit' onClick={handleModalClose}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default ManagePDF